import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WebsiteService} from '../../services/website.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    oEmailForm: FormGroup;

    constructor(private formBuilder: FormBuilder, private webSiteService: WebsiteService, private router: Router) {

        this.oEmailForm = this.formBuilder.group({
            name: [null, Validators.required],
            email: [null, Validators.required],
            phone: [null],
            subject: [null, Validators.required],
            message: [null, Validators.required],


        });

    }

    ngOnInit(): void {
    }


    submitForm(post: any) {

        console.log('enviando correo');

        var body = post as any;

        console.log(body);

        this.webSiteService.connectEndPointPOST(body, 'https://apiweb.easytechs.io/api/Invoices/sendMail')
            .subscribe({
                next: (data: any) => {

                },
                error: (error: any) => {
                    console.log(error);
                    if (error.error.exception) {
                        console.log('error1');
                        // swal.fire('Error', error.error.message, 'error');
                    } else {
                        console.log('error2');
                        // swal.fire('Error', error.message, 'error');
                    }
                },
                complete: () => {

                    this.router.navigate(['/']);

                }
            });

    }
}
