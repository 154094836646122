<app-navbar-style-four></app-navbar-style-four>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Facturacion Electronica</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>easyDTE</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="pricing-area pt-50 pb-100">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-after">easyDTE!</span>
            <h2 class="h2-color">Planes & <b>Precios</b></h2>
        </div>

        <div class="price-width">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg1">
                            <i class="flaticon-banner pricing-icon color-1"></i>
                            <h3 class="color-1">STANDARD</h3>
                            <div class="price-rate">
                                <h2 class="color-1">$ 25 + IVA</h2>
                                <span class="color-1">Mensuales</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 100 DTES</li>
                                <li><i class='bx bx-check'></i> 1 Usuario</li>
                                <li><i class='bx bx-check'></i> Actualizaciones Permanentes</li>
                                <li><i class='bx bx-check'></i> Soporte Tecnico</li>
                                <li><i class='bx  bx-right-arrow' style="color: orangered;"></i> Implementacion*</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg1">Contactanos!</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg2">
                            <i class="flaticon-mortgage-loan pricing-icon color-2"></i>
                            <h3 class="color-2">PLUS</h3>
                            <div class="price-rate">
                                <h2 class="color-2">$ 50 + IVA</h2>
                                <span class="color-2">Mensuales</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 250 DTES</li>
                                <li><i class='bx bx-check'></i>3 Usuarios</li>
                                <li><i class='bx bx-check'></i> Actualizaciones Permanentes</li>
                                <li><i class='bx bx-check'></i> Soporte Tecnico</li>
                                <li><i class='bx  bx-right-arrow' style="color: orangered;"></i> Implementacion*</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg2">Contactanos!</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="pricing-card">
                        <div class="pricing-card-into color-bg3">
                            <i class="flaticon-processing pricing-icon color-3"></i>
                            <h3 class="color-3">GOLD</h3>
                            <div class="price-rate">
                                <h2 class="color-3">$ 100 + IVA</h2>
                                <span class="color-3">Mensuales</span>
                            </div>
                            <ul>
                                <li><i class='bx bx-check'></i> Hasta 500 DTES</li>
                                <li><i class='bx bx-check'></i> 5 Usuarios</li>
                                <li><i class='bx bx-check'></i> Actualizaciones Permanentes</li>
                                <li><i class='bx bx-check'></i> Soporte Tecnico </li>
                                <li><i class='bx  bx-right-arrow' style="color: orangered;"></i> Implementacion*</li>
                            </ul>
                            <a routerLink="/contact" class="purchase-btn button-bg3">Contactanos!</a>
                        </div>
                    </div>
                </div>
                <small>* Cargo unico de acuerdo a catalogos a implementar.</small>
            </div>
        </div>
    </div>

<!-- 
    <div class="pricing-shape">
        <div class="shape1"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape8.png" alt="Images"></div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>
                
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two> -->