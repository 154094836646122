<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logoOrangeTransparent.png" alt="Logo"></a>
    </div>
    
    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logoWhiteTransparent.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Home <i class='bx bx-plus'></i></a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">SEO Agency</a></li>

                                <li class="nav-item"><a routerLink="/ai-startup" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AI Startup</a></li>

                                <li class="nav-item"><a routerLink="/machine-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Machine Learning</a></li>
                            </ul> -->
                        </li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sobre Nosotros <i class='bx bx-plus'></i></a></li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Pages <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0);" class="nav-link">Case study <i class='bx bx-plus'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/case-study" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study</a></li>

                                        <li class="nav-item"><a routerLink="/case-study-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case study Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Servicios<i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/dtepricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Facturacion Electronica</a></li>
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Desarrollo de Software</a></li>
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mobile Apps</a></li>
                                <li class="nav-item"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Development</a></li>

                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Blog <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a target="_blank" href="https://clientes.easytechs.io" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Area de Clientes <i class='bx bx-plus'></i></a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contactos <i class='bx bx-plus'></i></a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">
                        <!-- <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div> -->
                        <!-- <div class="side-item">
                            <div class="nav-add-btn">
                                <a href="https://app-beecakessv.easytechs.io" target="_blank" class="nav-menu-btn border-radius">AREA DE CLIENTES <i class='bx bx-plus'></i></a>
                            </div>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="circle-inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/contact" class="nav-menu-btn">Contactos<i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            
            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Buscar...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>