import {Injectable} from '@angular/core';
import {map} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class WebsiteService {

    constructor(private httpClient: HttpClient) {
    }

    connectEndPoint(endpoint: string) {
        return this.httpClient.get(`${endpoint}`).pipe(
            map((resp: any) => {
                return resp;
            })
        );
    }

    public connectEndPointPOST(body: any, endpoint: string) {

        return this.httpClient.post(`${endpoint}`, body)
            .pipe(map(data => {

                return data;

            }));
    }
}
